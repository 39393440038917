<template>
  <HomeBolao />
</template>

<script>
  import HomeBolao from '../components/HomeBolao.vue'

  export default {
    name: 'HomeView',

    components: {
      HomeBolao,
    },
  }
</script>
