<template>
  <v-app>
    <v-app-bar
      app
      color="success"
      dark
      v-if="token"
    >
      <div class="d-flex align-center">
        <v-img
          alt="Desenvolvido por Maurício Sávio"
          class="shrink mr-2"
          contain
          src="../src/assets/ubuntu.png"
          transition="scale-transition"
          width="40"
        />
      </div>
      <h3>Bolões MSOA</h3>
      <v-spacer></v-spacer>

      <v-btn @click="logout()">
        <span class="mr-2">{{nomeUsuario}}</span>
        <v-icon color="red">mdi-power</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',
    
  data: () => ({
    token: localStorage.getItem('ewetoken') || false,
    nomeUsuario : localStorage.getItem('nomeusuario'),
    auth : false
  }),
  methods:{
    logout(){
      localStorage.clear()
      window.location.href = '/'
    },
    noBackHist(){
      window.addEventListener('popstate', function () {
        // Impedir que a página volte
        history.pushState(null, '', window.location.href);
      });
    },
  },
  mounted(){
    //console.log(this.token)
    this.noBackHist()
  }
};
</script>
