<template>
  <v-card>          
    <v-card-title class="mr-2">{{titulo}} <v-spacer></v-spacer>
          <v-btn fab x-small color="error" @click="sair()"><v-icon small>mdi-close</v-icon></v-btn>
    </v-card-title>
    <v-card-subtitle><small>Disponíveis: {{cotasDisponiveis}} | Reservadas: {{ cotasReservadas }} | Pagas: {{ cotasPagas }}</small></v-card-subtitle>
    <v-card-subtitle><small>Cota: {{ valorcotabolao }} | Aposta: {{ valorAposta }}</small></v-card-subtitle>
    <v-data-table
      :headers="headers"
      :items="boloes"
      sort-by="calories"
      class="elevation-1"
    >
     <template v-slot:[`item.actions`]="{ item }">
      <div style="display: flex; flex-direction: row!important;">
        <v-icon v-if="item.idapostador > 0"
        class="mr-2"
        large
        @click="pagarCota(item)"
        :color="item.statuspagamento == 'P' ? 'success' : 'info'"
        
        :title="item.statuspagamento == 'P' ? 'Cota paga.' : 'Pagar cota.' "
        >{{ item.statuspagamento == 'P' ? 'mdi-check' : 'mdi-cash-plus' }}
        </v-icon>
        <v-icon v-if="!item.idapostador"
        class="mr-2"
        large
        color="error"
        title="Cota livre"
        >mdi-cancel
        </v-icon>       
        
        <v-icon v-if="item.statuspagamento == 'D'"
        class="mr-2"
        large
        title="Liberar Cota."
        color="purple"
        @click="liberarCota(item)"
        >
          mdi-lock-open-plus-outline
        </v-icon>
        
        <v-icon v-if="item.statuspagamento == 'P'"
        class="mr-2"
        large
        title="Cota paga, não é possivel liberar."
        color="info"
        >
          mdi-lock-outline
        </v-icon>
        <v-icon v-if="item.statuspagamento == 'A'"
        class="mr-2"
        large
        color="success"
        title="Cota livre."
        >
          mdi-lock-open-outline
        </v-icon>
      </div>
      </template>
      <template v-slot:no-data>
        <v-btn
          color="primary"
          @click="buscarBolaoAnalitico()"
        >
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn @click="sair()" color="error" fab small class="ma-2"><v-icon>mdi-logout</v-icon></v-btn>
    </v-card-actions>
  </v-card>
  </template>
  <script>
  
  export default {
    props :['idbolao'],
    data: () => ({
      dialog: false,
      dialogDelete: false,
      titulo : '',
      cotasDisponiveis : 0,
      cotasPagas : 0,
      cotasReservadas : 0,
      valorcotabolao : 0,
      valorAposta : '',
      headers: [
        {
          text: 'Cota ID',
          align: 'start',
          sortable: false,
          value: 'idcotabolao',
          
        },
        { text: 'Nome', value: 'apostador' },
        { text: 'R$ Cota', value: 'valorcota' },
        { text: 'sequencia', value: 'sequenciacota' },
        { text: 'Pagto', value: 'descstatuscota' },
        { text: 'Ações ', value: 'actions', sortable: false },
      ],
      boloes : [],
      editedIndex: -1,
      editedItem: {
        name: '',
        calories: 0,
        fat: 0,
        carbs: 0,
        protein: 0,
      },
      defaultItem: {
        name: '',
        calories: 0,
        fat: 0,
        carbs: 0,
        protein: 0,
      },
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
      this.buscarBolaoAnalitico()
    },

    methods: {
      initialize () {
      },
      async buscarBolaoAnalitico(){
        await this.$http.get('/apostas/bolaoanalitico', { params :{ idbolao : this.idbolao}}).then(response =>{
          if(response.status == 200){
            this.boloes = response.data
            this.titulo = this.boloes[0].titulo
            this.valorAposta = this.boloes[0].valoraposta
            this.valorcotabolao = this.boloes[0].valorcotabolao
            for(let index = 0; index < this.boloes.length; index++) {
                if(this.boloes[index].statuspagamento == 'P'){
                  this.cotasPagas++
                }else if(this.boloes[index].statuspagamento == 'A'){
                  this.cotasDisponiveis++
                }else if(this.boloes[index].statuspagamento == 'D'){
                  this.cotasReservadas++
                }
            }
          }
        }).catch(e =>{
          console.error(e)
        })
      },
      async pagarCota(item) {
        if(item.statuspagamento == 'D'){
            let body = {
            idcotabolao : item.idcotabolao,
            idbolao : this.idbolao
          }
          console.log('Cota ID:' + item.idcotabolao)
          this.$http.post('/apostas/pagarcota', body).then(response =>{
            if(response.status == 200){
              this.buscarBolaoAnalitico()
            }
          }).catch(e=>{
            console.error(e)
          })
        }else{
          return
        }



      },
      async liberarCota(item) {
        if(item.statuspagamento == 'D'){
            let body = {
            idcotabolao : item.idcotabolao,
            idbolao : this.idbolao
          }
          console.log('Cota ID:' + item.idcotabolao)
          this.$http.post('/apostas/liberarcota', body).then(response =>{
            if(response.status == 200){
              this.buscarBolaoAnalitico()
            }
          }).catch(e=>{
            console.error(e)
          })
        }else{
          return
        }



      },

/*       deleteItem (item) {
        this.editedIndex = this.desserts.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },


      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      }, */

/*       closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      }, */
      sair(){
        this.cotasPagas = 0
        this.cotasDisponiveis = 0
        this.cotasReservadas = 0
        this.$emit('fechar')
      },

  /*     save () {
        if (this.editedIndex > -1) {
          Object.assign(this.desserts[this.editedIndex], this.editedItem)
        } else {
          this.desserts.push(this.editedItem)
        }
        this.close()
      }, */
    },

  }
</script>