<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-img
          :src="require('../assets/ubuntu.png')"
          class="my-3"
          transition="scale-transition"
          contain
          height="200"
        />
      </v-col>

      <v-col class="mb-4">
        <h2>Bolões <strong>MSOA</strong></h2>
      </v-col>

      <v-col
        class="mb-5"
        cols="12"
        
      >
<template>
  <v-data-table
      :headers="headers"
      :items="boloes"
      :search="search"
      class="elevation-1"
      style="width: 1500px!important;"
    >
      <template v-slot:top>
        <v-toolbar
          flat
          class="elevation-1"
        >
          <v-toolbar-title style="width: 250px!important;"><small>Bolões Ativos</small></v-toolbar-title>
          <br>
          
          
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-spacer></v-spacer>
          <v-btn color="info" fab small @click="[dialogApostador = !dialogApostador]" title="Cadastrar Pessoas" class="mr-2"><v-icon>mdi-account-plus</v-icon></v-btn>
          <v-btn color="success" fab small @click="listarBoloes()" title="Atualizar Bolões" class="ml-2 mr-2"><v-icon>mdi-refresh</v-icon></v-btn>
          <v-btn color="orange" fab small @click="abrirModalBolao()" title="Novo Bolão" class="ml-2 mr-2"><v-icon color="white">mdi-cash-plus</v-icon></v-btn>
          <v-btn color="primary" fab small @click="listarBoloes()" title="Consultas" class="ml-2"><v-icon>mdi-magnify</v-icon></v-btn>
          <v-spacer></v-spacer>

          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>          
          <v-text-field
            class="ma-4"
            style="max-width: 350px!important;"
            v-model="search"
            append-icon="mdi-magnify"
            label="Buscar"
            single-line
            hide-details
            ></v-text-field>
         
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }" >
        <div style="display: flex; flex-direction: row!important;">
          
        <v-btn class="ml-1 mr-1"        
        x-small 
        fab
        color="info"
        :disabled="item.cotasdisponiveis < 1"
        title="Adicionar Participante"
        @click="[selectedItem = item, dialogAddParticipante = !dialogAddParticipante]"
        
        >
        <v-icon
          small
          color="white"
          
        >
          mdi-plus
        </v-icon>      
        </v-btn>
        <v-btn class="ml-1 mr-1"        
        x-small 
        fab
        color="orange"
        title="Bolão - Ver mais..."
        @click="[idbolao = item.idbolao, dialogAnalitico = !dialogAnalitico]"
        >
        <v-icon
          small
          color="white"
         
        >
          mdi-eye-plus
        </v-icon>

      
        </v-btn>
        <v-icon :color="item.cotaspagas == item.qtdecotas ? 'success' : 'error'">{{item.cotaspagas == item.qtdecotas ? 'mdi-check-circle' : 'mdi-progress-alert'}}</v-icon>
      </div>

      
      </template>

  

    </v-data-table>
</template>
        <v-row justify="center">

        </v-row>
      </v-col>

      <v-col
        class="mb-5"
        cols="12"
      >


        <v-row justify="center">

        </v-row>
      </v-col>

      <v-col
        class="mb-5"
        cols="12"
      >


        <v-row justify="center">

        </v-row>
      </v-col>
    </v-row>

    <div>
      <!-- DIALOGS -->
      
    </div>
    <v-dialog
      v-model="dialogAddParticipante"
      width="auto"
      v-if="dialogAddParticipante"
    >
      <v-card width="500">
       <v-card-title>Adicione um participante</v-card-title> 
       <v-card-text>



        
          <v-simple-table dense>
        <template v-slot:default>
<!--           <thead>
            <tr>
              <th class="text-left" colspan="2">
                <h4>Informe o CPF</h4>
              </th>
            </tr>
          </thead> -->
          <tbody>
            <tr>
              <td> <v-text-field label="CPF" v-model="documentoCPF" @keyup="buscarCPF()" append-icon="mdi-account" class="ma-2" style="width: 150px!important;" inputmode="numeric" :rules="cpfRules"></v-text-field></td>
              <td> <h5>{{ selectedItem.titulo +' | '+ selectedItem.valorcota }} </h5></td>
            </tr>
            <tr>
              <td colspan="2"><v-text-field label="Nome" v-model="nome" append-icon="mdi-account" class="ma-2" readonly></v-text-field></td>
            </tr>
            </tbody>
            </template>
            </v-simple-table>
            

      
       
        
      <div v-if="exibeQr">
      <v-row  class="ma-4 justify-center" >
        
        <qrcode-vue :value="value" level="H" size="150" />
    
        <!-- <v-progress-linear :buffer-value="tries + tries / 2" color="success" stream height="10" :value="tries"></v-progress-linear> -->
        
      </v-row>
      <v-row  class="ma-4 justify-center" >
        <v-progress-circular :rotate="-90" :size="75" :width="15" :value="tries" color="primary">{{ tries }}%</v-progress-circular>
      </v-row>
      <v-row class="justify-center">
        
        <v-text-field
        label="Valor"
        style="max-width: 150px!important;"
        v-model="valorPix"
        class="mr-2 ml-2 ">

        </v-text-field>
        
      </v-row>
      <v-row class="justify-center">
        
        <v-text-field
        label="Código de pagamento PIX"
        readonly
        v-model="value2"
        outlined
        class="mr-2 ml-2"
        id="inputCodPix"
        >
        </v-text-field>
        
      </v-row>
      <v-row class="justify-center">
        <v-btn fab x-small color="purple" title="Copiar" id="execCopy" @click="copiarPix()">
          <v-icon small color="white">
            mdi-content-copy
          </v-icon>
        </v-btn>
      </v-row>
    </div>
    <div v-if="!exibeQr">
      
      <v-row class="justify-center">
        <v-btn :disabled="btnPagarBloqueado" @click="calculaValorPix(selectedItem.valorcota)" color="info">Pagar</v-btn>

      </v-row>
      
    </div>
       </v-card-text>
       <v-card-actions>
        <v-btn
            class="ms-auto" @click="fecharAposta()" color="orange" fab small title="Abandonar"><v-icon color="white">mdi-logout</v-icon></v-btn>
       </v-card-actions>
          
        </v-card>
    </v-dialog>


    <v-dialog v-model="dialogApostador"  max-width="500" persistent>
        <Apostador @fechar="dialogApostador = !dialogApostador" v-if="dialogApostador"></Apostador>
    </v-dialog>
    <v-dialog v-model="dialogBolao"  max-width="500" persistent>
      <Bolao @fechar="fecharModalBolao()" v-model="dialogBolao" v-if="dialogBolao"></Bolao>
    </v-dialog>
    <v-dialog v-model="dialogAnalitico"  max-width="800" persistent>
      <BolaoAnalitico :idbolao="idbolao" @fechar="[listarBoloes() ,dialogAnalitico = !dialogAnalitico]" v-model="dialogAnalitico" v-if="dialogAnalitico"></BolaoAnalitico>
    </v-dialog>
    <div class="text-center">
    <v-dialog
      v-model="dialogPagto"
      width="350"
    >
      <v-card>
        <v-card-title class="text-h6">
          Pagamento
        </v-card-title>

        <v-card-text>
          
          <h4>{{msgTela}}</h4>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            small
            fab
            @click="fecharAposta()"
          >
            OK
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
  </v-container>
</template>

<script>
import BolaoAnalitico from "./BolaoAnalitico.vue"
import Bolao from "./Bolao.vue";
import Apostador from "./Apostador.vue";
import QrcodeVue from "qrcode.vue";
import { cpf } from "cpf-cnpj-validator";
export default {
  name: "HelloWorld",

  data: () => ({
    dialogAddParticipante: false,
    dialogApostador: false,
    dialogBolao : false,
    dialogAnalitico : false,
    dialogPagto : false,
    idapostador: 0,
    idcotabolao: 0,
    idbolao : 0,
    nome: "",
    documentoCPF: "",
    email: "",
    cpf: "",
    valorPix: 0,
    taxaPix: 2,
    btnPagarBloqueado: true,
    selectedItem: {},
    search: "",
    value: "",
    value2: "",
    exibeQr: false,
    msgTela : '',
    tempo : null,
    retries : 50,
    tries : 0,
    pagto : false,
    boloes: [ ],
    headers: [
      {
        text: "Bolão",
        align: "start",
        sortable: false,
        value: "titulo",
      },
      { text: "Cotas", value: "qtdecotas" },
      { text: "Disponível", value: "cotasdisponiveis" },
      { text: "Valor da Cota", value: "valorcota" },
      { text: "Qtde Dezenas", value: "qtdedezenas" },
      { text: "Dezenas", value: "dezenas" },
      { text: "Ações", value: "actions", sortable: false },
    ],
    cpfRules: [
      value => !!value || "Campo obrigatório, preencha corretamente",
      value => value.length == 11 || "11 caratéres obrigatórios",
      value => cpf.isValid(value) || "CPF Inválido",
    ],
  }),
  components: {
    QrcodeVue,
    Apostador,
    Bolao,
    BolaoAnalitico
  },
  methods: {
    onDataUrlChange(dataUrl) {
      this.dataUrl = dataUrl;
    },
    async calculaValorPix(vlrCota) {
      //alert(vlrCota)
      vlrCota = vlrCota.replace("R$", "");
      vlrCota = vlrCota.replace(",", ".");
      vlrCota = vlrCota.trim();
      //alert(vlrCota)
      let percFinal = 100 - this.taxaPix;
      if (vlrCota > 0) {
        this.valorPix = vlrCota * 100 / percFinal;
        this.valorPix = this.valorPix.toFixed(2);
        //console.log("AQUI"+this.valorPix);

        //busca proxima cota
        await this.$http
          .post("/apostas/buscarproxcotabolao", {
            idbolao: this.selectedItem.idbolao,
          })
          .then(async response => {
            if (response.status == 200) {
              this.idcotabolao = response.data[0].idcotabolao;
              //processa PIX
              let body = {
                valorcota: parseFloat(this.valorPix),
                email: this.email,
                nome: this.nome,
                idapostador: this.idapostador,
                idcotabolao: this.idcotabolao,
              };
              //console.log(body)
              
              await this.$http
                .post("/apostas/gerarpixcota", body)
                .then(response => {
                  
                  if (response.status == 200) {
                    this.value = response.data[0].codigopix;
                    this.value2 = response.data[0].codigopix;
                    this.valorPix = response.data[0].valorcota;
                    //let trys = 0
                    this.tempo = setInterval(async () => {
                                    if(await this.consultaRetornoPix()){

                                    /* if(this.pagto == true){ */
                                      
                                      clearInterval(this.tempo)
                                      this.tries = 0
                                      this.dialogPagto =true
                                      this.msgTela = "Pagamento aprovado!"
                                      setTimeout(() => {
                                        this.dialogPagto = false
                                        this.msgTela = ""
                                        this.fecharAposta()
                                      }, 4000)
                                      
                                      //this.pagto == false
                                    }
                                    
                                    this.tries++
                                    
                                    if(this.tries >= this.retries){
                                      clearInterval(this.tempo)
                                      this.tries = 0
                                      this.dialogPagto =true
                                      this.msgTela = "Timeout PIX"
                                      setTimeout(() => {
                                        this.dialogPagto = false
                                        this.msgTela = ""
                                        this.fecharAposta()
                                      }, 4000)
                                    }
                                  }, 4000);
                    
                  } else {
                    console.error(response);
                  }
                });
            } else {
              console.error(response);
              return;
            }
          })
          .catch(e => {
            console.error(e);
            return;
          });

        this.exibeQr = !this.exibeQr;
      }
    },

    async consultaRetornoPix(){
      let retorno = null
      await this.$http.get('/apostas/consultaretornopix',{ params: { idcotabolao : this.idcotabolao } }).then(response =>{
        console.log('Chamando...')
        if(response.status == 200){
          if(response.data[0].statuspagamento == 'P'){
            this.pagto = true
            retorno =  true
          }else{
            retorno = false
          }
        }
      }).catch(e=>{
        console.error(e)
      })
      return retorno
    },
    async salvarApostador() {
      if (this.validate()) {
        const colaborador = {
          nome: this.nome.toUpperCase(),
          cpf: this.documentoCPF.replace(/[^0-9]/g, ""),
          rg: this.rg,
          pis: this.pis,
          chavepix: this.chavePix.toLowerCase(),
          cep: this.cep.replace(/[^0-9]/g, ""),
          logradouro: this.logradouro.toUpperCase(),
          numeroendereco: this.numeroEndereco.toUpperCase(),
          bairro: this.bairro.toUpperCase(),
          cidade: this.cidade.toUpperCase(),
          uf: this.uf.toUpperCase(),
          telefone: this.telefone.replace(/[^0-9]/g, ""),
          datanasc: this.dataNascimento,
        };

        console.log(JSON.stringify(colaborador));
        if (this.idcolaborador > 0) {
          colaborador.idcolaborador = this.idcolaborador;
        }
        await this.$http
          .post("/auth/cadastracolaborador", colaborador, {
            headers: { "Content-Type": "application/json" },
          })
          .then(response => {
            if (response.status == 200) {
              this.limpar();
              //this.$emit("fechar");
            }
          });
      } else {
        return;
      }
    },
    async buscarCPF() {
      this.documentoCPF = this.documentoCPF.replace(/[^0-9]/g, "");

      if (this.documentoCPF.length == 11 && cpf.isValid(this.documentoCPF)) {
        if (cpf.isValid(this.documentoCPF)) {
          await this.$http
            .post("/auth/consultacpfapostador", {
              cpf: this.documentoCPF.replace(/[^0-9]/g, ""),
            })
            .then(response => {
              if (response.status == 200) {
                this.idapostador = response.data[0].idapostador;
                this.nome = response.data[0].nome;
                this.email = response.data[0].email;
                this.cpf = response.data[0].cpf;
                this.btnPagarBloqueado = false;
                return;
              }
            })
            .catch(e => {
              console.error(e);
            });
        } else {
          console.log("CPF inválido");
        }
      }
    },
    async listarBoloes() {
      await this.$http
        .get("/apostas/listarboloes")
        .then(response => {
          if (response.status == 200) {
            this.boloes = response.data;
            //console.log(this.boloes)
          }
        })
        .catch(e => [console.error(e)]);
    },
    fecharAposta() {
      this.dialogAddParticipante = false;
      this.exibeQr = false;
      this.dialogPagto = false
      clearInterval(this.tempo)
      this.tries = 0;
      this.documentoCPF = "";
      this.nome = "";
      this.btnPagarBloqueado = true;
      this.idapostador = 0;
      this.btnPagarBloqueado = true;
      this.listarBoloes();
    },
    abrirModalBolao(){
      this.dialogBolao = true
    },
    fecharModalBolao(){
      this.dialogBolao = !this.dialogBolao
      this.listarBoloes()
    },
    fecharModalPagto(){
      this.fecharAposta()
    },
    async copiarPix(){
      document.getElementById('execCopy').addEventListener('click', execCopy());
      function execCopy() {
      var cod = document.querySelector("#inputCodPix").select();
      
      try {
        document.execCommand("copy");
        //window.getSelection().removeAllRanges();
        console.log('Texto copiado com sucesso. ' + cod);
      } catch (err) {
        console.log('Não foi possível copiar!');
      }



      }
    }
  },

  created() {
    this.listarBoloes();
  },
  mounted(){
        let token = localStorage.getItem('ewetoken') || false
        if(!token){
          window.location.href = '/'
        }
      }
      
};
</script>
