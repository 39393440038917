<template>
  <div>
    
    <v-card>
      <v-form ref="form" v-model="valid" lazy-validation @submit.prevent>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-center" colspan="2">
              <h1>Cadastro de Bolão</h1>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><v-text-field label="Titulo" v-model="titulo" :rules="noTextNull"></v-text-field></td>
            <td><v-select label="Tipo" v-model="selected" :items="tiposBolao" item-text="tipo" item-value="dezenas" @change="alteraTotalDezenas(selected)" :rules="noZero" persistent-hint hint="Informe o tipo de Bolão"></v-select></td>
          </tr>
          <tr>
            <td><v-text-field inputmode="numeric" type="number" label="Qtde de Dezenas" v-model="totalDezenas" :rules="noZero"></v-text-field></td>
            <td><v-text-field inputmode="numeric" label="Valor da Aposta" v-model="valorAposta" :rules="noTextNull"  @blur="calcularValorCotas()"></v-text-field></td>
          </tr>
          <tr>
            <td><v-text-field inputmode="numeric" type="number" label="Qtde de Cotas" v-model="totalCotas" :rules="noTextNull"  @blur="calcularValorCotas()" min="0" data-number-to-fixed="2"></v-text-field></td>
            <td><v-text-field inputmode="numeric" label="Valor da Cota" v-model="valorCota" :rules="noTextNull" readonly></v-text-field></td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <div style="min-height: 300px!important;">
    <v-row class="justify-center ma-1">
        <v-btn 
        :color="botao.disabled ? 'orange' : botao.cor" 
        fab 
        x-small
        v-for="botao in botoes" 
        :key="botao.id" 
        class="ma-1" 
        style="color:white;"
        
        @click="[botao.disabled ? delete delDezena(botao.id, botao) : addDezena(botao.id, botao)]"
        >{{ botao.id }}</v-btn>

    </v-row>
    <v-row class="justify-center" style="display: flex; flex-direction: row;">


    </v-row>
    </div>
    <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" fab small @click="fecharModal()" class="ma-2"><v-icon >mdi-logout</v-icon></v-btn>
        <v-btn color="success" fab small @click="cadastrarBolao()" class="ma-2"><v-icon >mdi-check</v-icon></v-btn>
    </v-card-actions>
    </v-form>  
  </v-card>
  
    <!-- DIALOG -->
    <template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="350"
    >
      <v-card>
        <v-card-title class="text-h6">
          Cadastro de bolão <v-icon>{{icone}}</v-icon>
        </v-card-title>

        <v-card-text>
          
          <h4>{{msgTela}}</h4>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            small
            fab
            @click="fechar()"
          >
            OK
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
  </div>
  </template>
  <script>
  export default {
    name : "bolaoEwe",
    data () {
      return {
        dialog : false,
        valid : null,
        titulo : '',
        totalDezenas : 0,
        totalCotas : 0,
        valorCota : '',
        valorAposta : '',
        selected : 1,
        dezenasVolante : 0,
        botoes : [],
        dezenasSelecionadas : [],
        msgTela : '',
        icone : '',
        tiposBolao : [
            {   id : 1,
                tipo : 'Mega',
                dezenas : 60
            },
            {   id : 2,
                tipo : 'Quina',
                dezenas : 80
            },
            {   id : 3,
                tipo : 'LotoFacil',
                dezenas : 25
            },
        ],
        noTextNull: [value => !!value || "Campo obrigatório."],
        noZero: [value => !!value && value > 0 || "Campo obrigatório."],
      }
    },
    methods : {
        async alteraTotalDezenas(num){
            this.dezenasVolante = num
            this.botoes = []
            let botao = {}
            let ncor = ''

            if(this.dezenasVolante == 25){
                ncor = 'error'
            }else if(this.dezenasVolante == 60){
                ncor = 'success'
            }else if(this.dezenasVolante == 80){
                ncor = 'purple'
            }

            //console.log(botao)
            for (let index = 1; index <= this.dezenasVolante; index++) {
                //console.log(botao)
                botao = {cor : ncor, disabled : false}
                botao.id = index
                this.botoes.push(botao)
                
            }

           this.dezenasSelecionadas = []

        },
        addDezena(dez, botao){
            this.dezenasSelecionadas = this.dezenasSelecionadas.filter(Boolean)
            //console.log(dez)
            if(this.dezenasSelecionadas.length < this.totalDezenas){

                this.dezenasSelecionadas.push(dez)
                this.botoes[this.botoes.indexOf(botao)].disabled = true
                
            }
            console.log(JSON.stringify(this.dezenasSelecionadas))
        },

        delDezena(dez, botao){
            
            //console.log(dez)

            delete this.dezenasSelecionadas[this.dezenasSelecionadas.indexOf(dez)]   
            

            this.botoes[this.botoes.indexOf(botao)].disabled = false

            this.dezenasSelecionadas = this.dezenasSelecionadas.filter(Boolean)

            console.log(JSON.stringify(this.dezenasSelecionadas))  
            
            
        },
        async cadastrarBolao(){
          //this.valorAposta = this.valorAposta+''
          //this.valorAposta = this.valorAposta.replace('.',',')

          //this.valorCota = this.valorCota+''
          //this.valorCota = this.valorCota.replace('.',',')

          if(this.validate()){
            if(this.dezenasSelecionadas.length == this.totalDezenas){
              let bolao = {
                          titulo : this.titulo,
                          qtdedezenas : this.totalDezenas,
                          qtdecotas : this.totalCotas,
                          valoraposta : this.valorAposta,
                          valorcota : this.valorCota,
                          dezenas : this.dezenasSelecionadas.toString()
                      }
              console.log(bolao)
              this.$http.post('/apostas/cadastrarbolao', bolao).then(response =>{
                  if(response.status == 200){
                    this.msgTela = "Bolão cadastrado com sucesso"
                    this.icone = 'mdi-check'
                    this.dialog = true
                    setTimeout(() => {
                      this.dialog = false
                      this.msgTela = ''
                      this.fecharModal()
                    }, 4000);
                    
                  }else{
                    this.msgTela = "Erro ao cadastrar o bolão. Verifique!"
                    this.icone = 'mdi-exclamation-thick'
                    this.dialog = true
                    setTimeout(() => {
                      this.dialog = false
                      this.msgTela = ''
                    }, 5000);
                  }
              }).catch(e =>{
                  console.error(e)
              })
            }else{
              this.msgTela = "Informe quantide correta de dezenas ("+ this.totalDezenas + ")"
              this.icone = 'mdi-exclamation-thick'
              this.dialog = true
              setTimeout(() => {
                this.dialog = false
                this.msgTela = ''
              }, 5000);
            }
          }
        },
        calcularValorCotas(){
            this.valorAposta = this.valorAposta.replace(',','.')
            this.valorCota = this.valorCota.replace(',','.')

          if(this.valorAposta > 0 && this.totalCotas > 0) {



  
            this.valorCota = parseFloat(this.valorAposta / this.totalCotas).toFixed(2)
     
            console.log(this.valorCota)
            
            this.valorAposta = this.formatPrice(this.valorAposta)
            this.valorCota = this.formatPrice(this.valorCota)

          }
        },
        formatPrice(value) {
            let val = (value/1).toFixed(2).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },
        fechar(){

          this.dialog = false
          this.msgTela = ''

        },
        fecharModal(){
          this.dialog = false
          this.msgTela = ''
          this.$emit('fechar')
        },
        validate() {
          return this.$refs.form.validate();
        },
    }
  }
</script>