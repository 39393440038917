import Vue from 'vue'
import axios from 'axios'

axios.defaults.baseURL = (process.env.NODE_ENV == 'development') ? 'http://192.168.0.4:5132' : 'https://apiloto.deckyespetaria.com.br'


axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

const token = localStorage.getItem('ewetoken')

if (token) {
    axios.defaults.headers.common['x-access-token'] = token
}

Vue.use({
    install(Vue) {
        Vue.prototype.$http = axios
    }
})