<template>
    <div>
    <v-form ref="form" v-model="valid" lazy-validation @submit.prevent>
    <v-simple-table dense >
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-center" colspan="2">
              <h1>Cadastro de Apostador</h1>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th colspan="2" class="text-center">
            <h2>Dados Pessoais</h2>
            </th>
          </tr>
          <tr>
            <td><v-text-field class="mt-2" label="CPF" inputmode="numeric" append-icon="mdi-file-document" v-model="documentoCPF" @keyup="buscarCPF()" :rules="cpfRules" style="width: 140px!important;"></v-text-field></td>
            <!-- <td><v-text-field class="mt-2" label="RG" append-icon="mdi-file-document" v-model="rg" :rules="rgRules" style="width: 140px!important;"></v-text-field></td> -->
          </tr>
          <tr >
            <td colspan="2" class="center-text"><v-text-field class="mt-2" label="Nome Completo" append-icon="mdi-account" :rules="nomeCompletoRules" v-model="nome" style="max-width: 400px!important;" ></v-text-field></td>
          </tr>        
          <tr>
            <td><v-text-field type="date" label="Nascimento" style="width: 100px!important;" v-model="dataNascimento" :rules="dataNascRules" ></v-text-field></td>
            <td><v-text-field type="email" label="E-mail" :rules="emailRules" v-model="email" style="width: 140px!important;"></v-text-field></td>
          </tr>
          <tr>
            <td><v-text-field type="text" label="Telefone" v-mask="'(##) # ####-####'" append-icon="mdi-phone" style="width: 160px!important; font-size: 13px!important;" v-model="telefone" :rules="telefoneRules"></v-text-field></td>
            <td><v-text-field type="text" label="Chave PIX" append-icon="mdi-qrcode" placeholder="Tel, CPF(Somente números) ou e-mail" v-model="chavePix" :rules="noTextNull" style="max-width: 250px!important;"></v-text-field></td>
          </tr>
          <tr>
              <td colspan="2" class="text-center">
              <v-btn class="ma-2" color="error" @click="[limpar(), limpar(), fechar()]" fab small><v-icon>mdi-close-octagon</v-icon></v-btn> 
              <v-btn class="ma-2" color="success" @click="salvarApostador()" fab small><v-icon>mdi-content-save-check</v-icon></v-btn>
              <!-- <v-btn class="ma-2" color="success" @click="salvarColaborador()"><v-icon>mdi-content-save-check</v-icon></v-btn> -->
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    </v-form>
    </div>
  </template>
  <script>
import { cpf } from "cpf-cnpj-validator";
//import { ref } from '@vue/composition-api'
export default {
  name: "apostadorVue",
  data: () => ({
    passo: 1,
    idcolaborador: 0,
    nome: "",
    documentoCPF: "",
    rg: "",
    email: "",
    dataNascimento: "",
    valid: null,
    cep: "",
    logradouro: "",
    numeroEndereco: "",
    bairro: "",
    cidade: "",
    uf: "",
    telefone: "",
    chavePix: "",
    nomeCompletoRules: [
      value => !!value || "Campo obrigatório, preencha corretamente",
      value => /[^0-9]/.test(value) || "Nomes não podem conter números.",
    ],
    cpfRules: [
      value => !!value || "Campo obrigatório, preencha corretamente",
      value => value.length == 11 || "11 caratéres obrigatórios",
      value => cpf.isValid(value) || "CPF Inválido",
    ],
    emailRules: [
      v => !!v || "E-mail é obrigatório",
      v => /.+@.+\..+/.test(v) || "E-mail inválido",
    ],
    dataNascRules: [value => value.length == 10 || "Preencha corretamente."],
    telefoneRules: [
      value => !!value || "Campo obrigatório",
      value => value.replace(/[^0-9]/g, "").length == 11 || "Celular inválido",
    ],
    pixRules: [value => !!value || "Campo obrigatório"],
    noTextNull: [value => !!value || "Campo obrigatório."],
  }),
  methods: {
    async salvarApostador() {
      if (this.validate()) {
        const apostador = {
          nome: this.nome.toUpperCase(),
          cpf: this.documentoCPF.replace(/[^0-9]/g, ""),
          email: this.email,
          chavepix: this.chavePix.toLowerCase(),
          telefone: this.telefone.replace(/[^0-9]/g, ""),
          datanasc: this.dataNascimento,
        };

        console.log(JSON.stringify(apostador));
        if (this.idapostador > 0) {
          apostador.idapostador = this.idapostador;
        }
        await this.$http
          .post("/auth/cadastraapostador", apostador, {
            headers: { "Content-Type": "application/json" },
          })
          .then(response => {
            if (response.status == 200) {
              this.limpar();
              this.$emit("fechar");
            }
          });
      } else {
        return;
      }
    },
    limpar() {
      this.idcolaborador = 0;
      this.nome = "";
      this.documentoCPF = "";
      this.rg = "";
      this.chavePix = "";
      this.pis = "";
      this.cep = "";
      this.logradouro = "";
      this.numeroEndereco = "";
      this.bairro = "";
      this.cidade = "";
      this.uf = "";
      this.telefone = "";
      this.dataNascimento = "";
    },
    fechar() {
      this.$emit("fechar");
    },
    buscaCEP() {
      const zip = this.cep.replace(/[^0-9]/g, "");
      if (zip.length == 8) {
        this.$viaCep.buscarCep(this.cep).then(obj => {
          //console.log(obj)
          this.logradouro = obj.logradouro;
          this.uf = obj.uf;
          this.cidade = obj.localidade;
          this.bairro = obj.bairro;

          document.getElementById("numEnd").focus();
        });
      }
    },
    buscarCPF() {
      this.documentoCPF = this.documentoCPF.replace(/[^0-9]/g, "");

      if (this.documentoCPF.length == 11 && cpf.isValid(this.documentoCPF)) {
        if (cpf.isValid(this.documentoCPF)) {
          this.$http
            .post("/auth/consultacpfapostador", {
              cpf: this.documentoCPF.replace(/[^0-9]/g, ""),
            })
            .then(response => {
              if (response.status == 200) {
                this.idapostador = response.data[0].idapostador;
                this.email = response.data[0].email;
                this.nome = response.data[0].nome;
                this.documentoCPF = response.data[0].cpf;
                this.chavePix = response.data[0].chavepix;
                this.telefone = response.data[0].telefone;
                this.dataNascimento = response.data[0].datanasc.substring(
                  0,
                  10
                );
                return;
              }
            })
            .catch(e => {
              console.error(e);
            });
        } else {
          console.log("CPF inválido");
        }
      }
    },
    validate() {
      return this.$refs.form.validate();
    },
  },
};
</script>