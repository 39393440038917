<template>
    <v-container style="max-width: 450px!important;">
        <v-row class="text-center">
        <v-col cols="12">
          <v-row class="text-center">
      <v-col cols="12">
        <v-img
          :src="require('../assets/ubuntu.png')"
          class="my-3"
          transition="scale-transition"
          contain
          height="200"
        />
      </v-col>

      <v-col>
        <h2>Bolões <strong>MSOA</strong></h2>
      </v-col>
      </v-row>
      </v-col>
      </v-row>
    <v-row class="justify-center mt-4" >
        <v-form ref="form" v-model="valid" lazy-validation @submit.prevent>
    <v-simple-table >
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-center" colspan="2">
              <h1>Cadastre-se</h1>
            </th>
          </tr>
        </thead>
        <tbody>

          <tr>
            <td><v-text-field class="mt-2" label="CPF" inputmode="numeric" append-icon="mdi-file-document" v-model="documentoCPF" :rules="cpfRules" style="width: 140px!important;" @keyup="buscarCPF()"></v-text-field></td>
            <!-- <td><v-text-field class="mt-2" label="RG" append-icon="mdi-file-document" v-model="rg" :rules="rgRules" style="width: 140px!important;"></v-text-field></td> -->
          </tr>
          <tr >
            <td colspan="2" class="center-text"><v-text-field class="mt-2" label="Nome Completo" append-icon="mdi-account" :rules="nomeCompletoRules" v-model="nome" style="max-width: 400px!important;" ></v-text-field></td>
          </tr>        
          <tr>
            <td><v-text-field type="date" label="Nascimento" style="width: 100px!important;" v-model="dataNascimento" :rules="dataNascRules" ></v-text-field></td>
            <td><v-text-field type="email" label="E-mail" :rules="emailRules" v-model="email" style="width: 140px!important;"></v-text-field></td>
          </tr>
          <tr>
            <td><v-text-field type="text" label="Telefone" v-mask="'(##) # ####-####'" append-icon="mdi-phone" style="width: 160px!important; font-size: 13px!important;" v-model="telefone" :rules="telefoneRules"></v-text-field></td>
            <td><v-text-field type="text" label="Chave PIX" append-icon="mdi-qrcode" placeholder="Tel, CPF(Somente números) ou e-mail" v-model="chavePix" :rules="noTextNull" style="max-width: 250px!important;"></v-text-field></td>
          </tr>
          <tr>
              <td colspan="2" class="text-center">
              <v-btn class="ma-2" color="error" @click="limpar()" fab small><v-icon>mdi-close-octagon</v-icon></v-btn> 
              <v-btn class="ma-2" color="success" @click="salvarApostador()" fab small><v-icon>mdi-content-save-check</v-icon></v-btn>
              <!-- <v-btn class="ma-2" color="success" @click="salvarColaborador()"><v-icon>mdi-content-save-check</v-icon></v-btn> -->
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    </v-form>
    
    </v-row>
    <template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="500"
    >

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Auto Cadastro Bolão
        </v-card-title>

        <v-card-text>
            <v-row class="text-h6 justify-text ma-4">
                {{ msgTela }}
            </v-row>
          
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            fab
            small
            @click="finalizar()"
          >
            OK
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
    </v-container>
  </template>
  <script>
import { cpf } from "cpf-cnpj-validator";
//import { ref } from '@vue/composition-api'
export default {
  name: "apostadorVue",
  data: () => ({
    passo: 1,
    dialog : false,
    msgTela : '',
    idcolaborador: 0,
    nome: "",
    documentoCPF: "",
    rg: "",
    email: "",
    dataNascimento: "",
    valid: null,
    cep: "",
    logradouro: "",
    numeroEndereco: "",
    bairro: "",
    cidade: "",
    uf: "",
    telefone: "",
    chavePix: "",
    nomeCompletoRules: [
      value => !!value || "Campo obrigatório, preencha corretamente",
      value => /[^0-9]/.test(value) || "Nomes não podem conter números.",
    ],
    cpfRules: [
      value => !!value || "Campo obrigatório, preencha corretamente",
      value => value.length == 11 || "11 caratéres obrigatórios",
      value => cpf.isValid(value) || "CPF Inválido",
    ],
    emailRules: [
      v => !!v || "E-mail é obrigatório",
      v => /.+@.+\..+/.test(v) || "E-mail inválido",
    ],
    dataNascRules: [value => value.length == 10 || "Preencha corretamente."],
    telefoneRules: [
      value => !!value || "Campo obrigatório",
      value => value.replace(/[^0-9]/g, "").length == 11 || "Celular inválido",
    ],
    pixRules: [value => !!value || "Campo obrigatório"],
    noTextNull: [value => !!value || "Campo obrigatório."],
  }),
  methods: {
    async salvarApostador() {
      if (this.validate()) {
        const apostador = {
          nome: this.nome.toUpperCase(),
          cpf: this.documentoCPF.replace(/[^0-9]/g, ""),
          email: this.email,
          chavepix: this.chavePix.toLowerCase(),
          telefone: this.telefone.replace(/[^0-9]/g, ""),
          datanasc: this.dataNascimento,
        };

        //console.log(JSON.stringify(apostador));
        if (this.idapostador > 0) {
          apostador.idapostador = this.idapostador;
        }
        await this.$http
          .post("/auth/cadastraapostador", apostador, {
            headers: { "Content-Type": "application/json" },
          })
          .then(response => {
            if (response.status == 200) {
              this.limpar();
              this.msgTela = "Cadastrado com sucesso!"
              this.dialog = true
            }
          });
      } else {
        return;
      }
    },
    limpar() {
      this.idcolaborador = 0;
      this.nome = "";
      this.documentoCPF = "";
      this.rg = "";
      this.chavePix = "";
      this.telefone = "";
      this.email = ""
    },
    buscaCEP() {
      const zip = this.cep.replace(/[^0-9]/g, "");
      if (zip.length == 8) {
        this.$viaCep.buscarCep(this.cep).then(obj => {
          //console.log(obj)
          this.logradouro = obj.logradouro;
          this.uf = obj.uf;
          this.cidade = obj.localidade;
          this.bairro = obj.bairro;

          document.getElementById("numEnd").focus();
        });
      }
    },
    finalizar(){
        this.dialog = false
        window.location.reload()
    },
    buscarCPF() {
      this.documentoCPF = this.documentoCPF.replace(/[^0-9]/g, "");

      if (this.documentoCPF.length == 11 && cpf.isValid(this.documentoCPF)) {
        if (cpf.isValid(this.documentoCPF)) {
          this.$http
            .post("/auth/consultacpfapostador", {
              cpf: this.documentoCPF.replace(/[^0-9]/g, ""),
            })
            .then(response => {
              if (response.status == 200) {
                    this.msgTela = 'CPF já está cadastrado.'
                    this.dialog = true
                return;
              }
            })
            .catch(e => {
              console.error(e);
            });
        } else {
          console.log("CPF inválido");
        }
      }
    },
    validate() {
      return this.$refs.form.validate();
    },
  },
};
</script>